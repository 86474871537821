export const CONTENT = {
  REGISTRATION_HEADING: "Регистрация",
  PHONE_TITLE: "Номер телефона",
  EMAIL_TITLE: "Ваш e-mail",
  CONTINUE_BUTTON: "Продолжить",
  TIMER_REPEAT: "Повторно через: ",
  REGISTRATION_TEXT_ONE: "После нажатия на кнопку ",
  REGISTRATION_TEXT_TWO:
    " вы получите сообщение с вашим паролем на указанную почту",
  EMAIL_PLACEHOLDER: "Введите",
  PHONE_PLACEHOLDER: "+7 (___) ___ __ __",
  MAIL_SENT_FIRST: "Мы отправили письмо на почту ",
  MAIL_SENT_SECOND:
    "Пожалуйста, пройдите по ссылке из письма для подтверждения почты и получения пароля.",
  MAIL_SENT_THIRD: "Вы сможете сменить пароль на собственный после регистрации",
  BUTTON_BACK: "Назад",
  BUTTON_ONE_MORE_MAIL: "Отправить еще раз",
  INN: "ИНН",
  BUTTON_CHECK: "Проверить",
  BUTTON_ENTER: "Войти",
  OFERTA_TEXT_ONE: "Нажимая на кнопку ",
  OFERTA_TEXT_TWO:
    " вы соглашаетесь, что расчет налогов будет производиться только на основании данных, успешно загруженных в сервис. Регистрируясь, вы принимаете ",
  OFERTA_LINK_TWO: "политикой обработки персональных данных.",
  OFERTA_LINK_ONE: "условия лицензионного договора",
  TEXT_AND: " и соглашаетесь с ",
  NAME: "Название",
  DATE_REGISTRATION: "Дата регистрации",
  NAME_MOCKED: "ИП Пупкин Василий Петрович",
  DATE_REGISTRATION_MOCKED: "20.09.2020",
  USN_INCOME: "Ставка УСН Доходы",
  OFERTA_REGISTER_ONE: "Нажимая на кнопку ",
  OFERTA_REGISTER_ONE_TWO:
    " вы соглашаетесь, что расчет налогов будет производиться только на основании данных, успешно загруженных в сервис. Регистрируясь, вы принимаете ",

  OFERTA_LINK: "условия лицензионного договора",
  PERSONAL_DATA_LINK: "политикой обработки персональных данных",
  OFERTA_REGISTER_TWO: " и соглашаетесь с ",
  OFERTA_REGISTER_THREE: " и получение информационных сообщений от ООО “АК”",
  PASSWORD_MAIL_TEXT: "Пароль из письма",
  PASSWORD_MAIL_PLACEHOLDER: "Введите",
  NECESSARY: " *",
  JUSTIFICATION_TITLE: "Обоснование ставки",
  SELECT_JUSTIFICATION_TITLE: "Причина снижения ставки",
  INPUT_ARTICLE_TITLE: "Статья",
  INPUT_PARAGRAPH_TITLE: "Пункт",
  INPUT_SUBPARAGRAPH_TITLE: "Подпункт",
  INPUT_PLACEHOLDER: "Введите",
  SELECT_PLACEHOLDER: "Выберите",
  ALREADY_HAVE: "Уже есть профиль?",
  AUTHORIZATION_LINK: "Авторизация",

  INPUT_ERROR_HINT: "Это поле обязательно для заполнения",
  INPUT_FAULT_HINT: "Поле заполнено некорректно",
  FIRST_JUSTIFICATION_DESCRIPTION:
    "Укажите обоснование из регионального законодательства",
  SECOND_JUSTIFICATION_DESCRIPTION:
    "Эта информация отразится в Декларации по УСН",
}
