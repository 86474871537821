import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const HaveBannersIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.1291 9.62172C11.8255 9.09891 11.3744 7.61961 11.3744 5.6875C11.3744 4.52718 10.9134 3.41438 10.093 2.59391C9.27249 1.77344 8.15969 1.3125 6.99937 1.3125C5.83904 1.3125 4.72625 1.77344 3.90577 2.59391C3.0853 3.41438 2.62437 4.52718 2.62437 5.6875C2.62437 7.62016 2.17265 9.09891 1.86913 9.62172C1.79162 9.75463 1.75053 9.90563 1.75001 10.0595C1.74948 10.2134 1.78953 10.3646 1.86612 10.4981C1.94272 10.6315 2.05314 10.7424 2.18627 10.8196C2.31939 10.8967 2.4705 10.9374 2.62437 10.9375H4.85616C4.9571 11.4314 5.22554 11.8753 5.61606 12.1941C6.00659 12.5129 6.49524 12.687 6.99937 12.687C7.50349 12.687 7.99214 12.5129 8.38267 12.1941C8.7732 11.8753 9.04163 11.4314 9.14257 10.9375H11.3744C11.5282 10.9373 11.6792 10.8965 11.8123 10.8194C11.9453 10.7422 12.0557 10.6313 12.1322 10.4978C12.2087 10.3644 12.2487 10.2132 12.2482 10.0594C12.2476 9.90554 12.2065 9.75459 12.1291 9.62172ZM6.99937 11.8125C6.72802 11.8124 6.46336 11.7282 6.24183 11.5715C6.0203 11.4148 5.85278 11.1933 5.76233 10.9375H8.2364C8.14595 11.1933 7.97843 11.4148 7.7569 11.5715C7.53537 11.7282 7.27071 11.8124 6.99937 11.8125ZM2.62437 10.0625C3.04546 9.33844 3.49937 7.66062 3.49937 5.6875C3.49937 4.75924 3.86811 3.869 4.52449 3.21263C5.18087 2.55625 6.07111 2.1875 6.99937 2.1875C7.92762 2.1875 8.81786 2.55625 9.47424 3.21263C10.1306 3.869 10.4994 4.75924 10.4994 5.6875C10.4994 7.65898 10.9522 9.3368 11.3744 10.0625H2.62437Z"
      fill="#6159FF"
    />
  </svg>
)
