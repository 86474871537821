import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

interface ArrowCounterIconProps
  extends JSX.IntrinsicAttributes,
    SVGProps<SVGSVGElement> {
  className?: string
}

export const NonIcon = ({
  className = "",
  ...props
}: ArrowCounterIconProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} arrow-color-icon`}
    {...props}
  >
    <path d="M15.023 5.78953L11.0855 1.85203C11.0332 1.79981 10.9711 1.7584 10.9029 1.73017C10.8346 1.70194 10.7614 1.68744 10.6875 1.6875H3.9375C3.63913 1.6875 3.35298 1.80603 3.142 2.017C2.93103 2.22798 2.8125 2.51413 2.8125 2.8125V15.1875C2.8125 15.4859 2.93103 15.772 3.142 15.983C3.35298 16.194 3.63913 16.3125 3.9375 16.3125H14.0625C14.3609 16.3125 14.647 16.194 14.858 15.983C15.069 15.772 15.1875 15.4859 15.1875 15.1875V6.1875C15.1876 6.11361 15.1731 6.04043 15.1448 5.97215C15.1166 5.90386 15.0752 5.84181 15.023 5.78953ZM11.25 3.60773L13.2673 5.625H11.25V3.60773ZM14.0625 15.1875H3.9375V2.8125H10.125V6.1875C10.125 6.33668 10.1843 6.47976 10.2898 6.58525C10.3952 6.69074 10.5383 6.75 10.6875 6.75H14.0625V15.1875Z" />
    <rect x="6" y="6" width="12" height="12" rx="6" fill="#F0F0F0" />
    <path d="M15.7729 14.977C15.8786 15.0827 15.938 15.226 15.938 15.3754C15.938 15.5249 15.8786 15.6682 15.7729 15.7739C15.6673 15.8795 15.524 15.9389 15.3745 15.9389C15.2251 15.9389 15.0817 15.8795 14.9761 15.7739L12 12.7968L9.02295 15.7729C8.91728 15.8786 8.77395 15.938 8.62451 15.938C8.47507 15.938 8.33175 15.8786 8.22607 15.7729C8.1204 15.6673 8.06104 15.5239 8.06104 15.3745C8.06104 15.225 8.1204 15.0817 8.22607 14.9761L11.2031 12L8.22701 9.02293C8.12134 8.91726 8.06197 8.77393 8.06197 8.62449C8.06197 8.47505 8.12134 8.33173 8.22701 8.22605C8.33268 8.12038 8.47601 8.06102 8.62545 8.06102C8.77489 8.06102 8.91821 8.12038 9.02389 8.22605L12 11.2031L14.977 8.22558C15.0827 8.11991 15.226 8.06055 15.3754 8.06055C15.5249 8.06055 15.6682 8.11991 15.7739 8.22558C15.8796 8.33126 15.9389 8.47458 15.9389 8.62402C15.9389 8.77347 15.8796 8.91679 15.7739 9.02246L12.7969 12L15.7729 14.977Z" />
  </svg>
)
