export const CONTENT = {
  HEADING_MODAL: "Добавить уплату",
  DESCRIPTION_MODAL:
    "Укажите информацию об уплаченных налогах и взносах, сведения о которых не содержатся в загруженных выписках",
  TEXT_AMOUNT: "Сумма уплаты",
  DATEPICKER_PLACEHOLDER: "Выберите дату",
  SELECT_YEAR_PLACEHOLDER: "Выберите год",
  TEXT_DATE: "Дата уплаты",
  TEXT_YEAR: "Год, за который осуществлялась уплата*",
  TEXT_PAYMENT_NUMBER: "Номер платежного документа",
  DESCRIPTION_PAYMENT_NUMBER:
    "Укажите номер платежного документа в банке или номер чека (квитанции)",
  BUTTON_ADD_PAYMENT: "Добавить платеж",
  BUTTON_PAY: "Добавить уплату",
  BUTTON_PAY_MORE: "Добавить уплату взноса",
  TEXT_REMARK: "* поля, обязательные для заполнения",
  NEXT_PAYMENT: "Дополнительный платеж",
  NOTIFICATION_PROCESSING_SUCCESS: "Уплата налогов и взносов добавлена",
  NOTIFCATION_PROCESSING_ERROR: "Ошибка добавления уплаты. Попробуйте еще раз",
  UPDATE_TITLE: "Уплаты налогов и взносов формируют баланс ЕНС",
  UDPATE_DESCRIPTION:
    "Независимо от того, какой налог или взнос вы оплатили, оплата зачтется в погашение обязательств ИП или физлица с наиболее ранним сроком уплаты",
  UDPATE_LINK: "Подробнее →",
  NECESSARY: " *",
  INPUT_PLACEHOLDER: "Введите",
  INPUT_ERROR_HINT: "Это поле обязательно для заполнения",
  INPUT_FAULT_HINT: "Поле заполнено некорректно",
}
