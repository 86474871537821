import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const RegisterWelcomeImage = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="175"
    height="136"
    viewBox="0 0 575 636"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      cx="283.697"
      cy="625.513"
      rx="283.697"
      ry="10.4869"
      fill="#C6C4FF"
    />
    <path
      d="M167.283 233.876H117.308C115.917 233.876 114.54 233.601 113.255 233.069C111.97 232.536 110.802 231.755 109.82 230.772C108.837 229.787 108.058 228.619 107.526 227.334C106.995 226.048 106.723 224.67 106.725 223.28V103.148C106.725 101.759 106.999 100.383 107.53 99.0994C108.062 97.8165 108.842 96.6503 109.825 95.668C110.808 94.6856 111.975 93.9068 113.259 93.376C114.543 92.8452 115.919 92.5719 117.308 92.5733H199.813C201.206 92.5685 202.585 92.839 203.873 93.3678C205.16 93.8972 206.33 94.6754 207.317 95.6577C208.303 96.6401 209.086 97.8076 209.62 99.0926C210.155 100.378 210.431 101.756 210.431 103.148V135.55"
      stroke="#CEB7FF"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M197.307 107.129H120.897C117.973 107.129 115.602 109.499 115.602 112.423V126.687C115.602 129.611 117.973 131.982 120.897 131.982H197.307C200.231 131.982 202.602 129.611 202.602 126.687V112.423C202.602 109.499 200.231 107.129 197.307 107.129Z"
      stroke="#CEB7FF"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M158.565 117.595C159.984 117.595 161.134 116.445 161.134 115.027C161.134 113.608 159.984 112.458 158.565 112.458C157.146 112.458 155.996 113.608 155.996 115.027C155.996 116.445 157.146 117.595 158.565 117.595Z"
      fill="#CEB7FF"
    />
    <path
      d="M151.383 126.66C151.323 125.681 151.463 124.7 151.796 123.777C152.13 122.854 152.648 122.009 153.32 121.294C153.992 120.58 154.804 120.01 155.704 119.621C156.605 119.231 157.576 119.03 158.557 119.03C159.538 119.03 160.509 119.231 161.41 119.621C162.31 120.01 163.122 120.58 163.794 121.294C164.466 122.009 164.985 122.854 165.318 123.777C165.651 124.7 165.792 125.681 165.731 126.66H151.383Z"
      fill="#CEB7FF"
    />
    <path
      d="M159.09 158.619C156.351 158.619 153.673 159.431 151.396 160.953C149.118 162.475 147.343 164.637 146.295 167.167C145.247 169.698 144.972 172.482 145.506 175.168C146.041 177.855 147.36 180.321 149.297 182.258C151.234 184.195 153.701 185.514 156.388 186.048C159.075 186.582 161.86 186.308 164.39 185.26C166.921 184.212 169.084 182.437 170.606 180.16C172.128 177.883 172.94 175.205 172.94 172.467C172.941 170.648 172.584 168.847 171.888 167.167C171.192 165.486 170.172 163.959 168.886 162.673C167.599 161.387 166.072 160.367 164.392 159.672C162.711 158.976 160.909 158.618 159.09 158.619ZM159.09 182.967C158.611 182.887 158.175 182.641 157.861 182.27C157.547 181.9 157.374 181.43 157.374 180.944C157.374 180.459 157.547 179.989 157.861 179.618C158.175 179.248 158.611 179.002 159.09 178.922C159.383 178.874 159.684 178.89 159.971 178.969C160.257 179.049 160.523 179.189 160.75 179.382C160.977 179.574 161.159 179.814 161.284 180.084C161.409 180.354 161.474 180.647 161.474 180.944C161.474 181.242 161.409 181.536 161.284 181.806C161.159 182.076 160.977 182.315 160.75 182.507C160.523 182.699 160.257 182.84 159.971 182.919C159.684 182.999 159.383 183.015 159.09 182.967ZM161.653 167.398C161.323 170.46 160.759 173.493 159.965 176.47C159.882 176.629 159.756 176.762 159.603 176.854C159.449 176.947 159.273 176.997 159.094 176.997C158.914 176.997 158.738 176.947 158.585 176.854C158.431 176.762 158.306 176.629 158.223 176.47C157.591 174.276 157.106 172.041 156.774 169.782C156.494 168.142 155.537 165.478 156.453 163.879C157.259 162.452 159.541 162.281 160.86 163.025C162.479 163.948 161.844 165.902 161.653 167.398Z"
      fill="#9984FF"
    />
    <path
      d="M124.299 143.077H191.949"
      stroke="#CEB7FF"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M124.299 149.738H191.949"
      stroke="#CEB7FF"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M124.299 192.605H191.949"
      stroke="#CEB7FF"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M140.015 198.906H176.235"
      stroke="#CEB7FF"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M542.233 208.204H438.534C429.9 208.204 422.9 215.202 422.9 223.835V400.914C422.9 409.547 429.9 416.544 438.534 416.544H542.233C550.867 416.544 557.867 409.547 557.867 400.914V223.835C557.867 215.202 550.867 208.204 542.233 208.204Z"
      fill="#6159FF"
    />
    <path
      d="M400.484 208.204H328.276C319.642 208.204 312.643 215.202 312.643 223.835V400.914C312.643 409.547 319.642 416.544 328.276 416.544H400.484C409.118 416.544 416.117 409.547 416.117 400.914V223.835C416.117 215.202 409.118 208.204 400.484 208.204Z"
      fill="#6159FF"
    />
    <path
      d="M318.621 522.485H557.123C561.244 522.483 565.196 520.847 568.11 517.934C571.025 515.021 572.664 511.071 572.668 506.951V210.949C572.668 208.908 572.267 206.886 571.486 205C570.705 203.114 569.56 201.401 568.117 199.957C566.673 198.514 564.959 197.369 563.073 196.588C561.187 195.808 559.165 195.406 557.123 195.407H395.038L352.675 195.66H352.552L331.132 196.493H331.063L325.426 196.712H324.927L324.511 200.066L323.691 206.303C323.7 206.315 323.704 206.329 323.704 206.344C323.704 206.359 323.7 206.373 323.691 206.385L317.022 216.264L315.656 218.265L311.891 242.353V242.469C311.809 242.435 311.727 242.387 311.624 242.333C311.542 242.285 311.464 242.23 311.392 242.169C310.125 241.215 309.173 239.904 308.659 238.405C307.408 235.235 307.108 231.033 307.094 227.7L306.274 246.001V246.07L305.133 270.52L304.853 277.085C305.386 276.135 306.151 274.769 307.039 273.136L304.204 291.253L303.616 304.308L302.154 337.297L302.099 338.527V338.998L301.717 347.032V347.217C301.717 347.217 300.624 370.792 300.624 370.826L300.521 373.012L299.257 382.556L295.567 409.26V506.951C295.568 509.734 296.318 512.466 297.739 514.859C299.161 517.252 301.2 519.219 303.644 520.552C308.269 523.059 313.387 522.725 318.471 522.554C319.837 522.513 321.204 522.451 322.57 522.376"
      fill="#6159FF"
    />
    <path
      d="M309.567 517.39C309.471 517.39 309.369 517.301 309.252 517.267C307.953 516.802 306.726 516.158 305.604 515.354C303.635 513.956 302.032 512.105 300.927 509.958C299.823 507.812 299.249 505.431 299.256 503.017V372.644L295.566 374.414L295.334 374.53L294.835 373.293L292.369 367.145L291.686 365.444L285.447 349.875C285.447 349.875 297.295 301.945 305.132 270.52C308.002 258.982 310.339 249.664 311.323 245.749C311.623 244.581 311.801 243.898 311.835 243.782C311.838 243.755 311.838 243.727 311.835 243.7C311.856 243.467 311.82 243.233 311.733 243.017C311.636 242.765 311.519 242.52 311.384 242.286C311.368 242.237 311.345 242.191 311.316 242.149C310.503 240.767 309.59 239.447 308.583 238.201L307.285 222.126L307.496 221.962L308.48 221.177L315.204 215.848L316.768 211.708L319.836 203.661L321.203 200.074H324.455H325.289H331.172L352.913 200.006H354.779L396.028 199.76H553.856C555.844 199.76 557.812 200.151 559.648 200.912C561.484 201.673 563.151 202.789 564.556 204.195C565.96 205.601 567.073 207.27 567.832 209.107C568.59 210.943 568.98 212.911 568.977 214.898V503.017C568.98 505.004 568.59 506.971 567.832 508.808C567.073 510.645 565.96 512.314 564.556 513.72C563.151 515.126 561.484 516.241 559.648 517.002C557.812 517.763 555.844 518.155 553.856 518.155H318.907"
      fill="#DFDFFF"
    />
    <path
      d="M325.359 195.81L330.948 195.776H331.064L352.519 195.66H352.669L395.032 195.407H557.124C561.246 195.409 565.199 197.047 568.114 199.961C571.029 202.875 572.666 206.827 572.669 210.949V506.951C572.665 511.071 571.026 515.021 568.111 517.934C565.197 520.847 561.245 522.483 557.124 522.485H310.689C306.674 522.485 302.822 520.89 299.983 518.051C297.143 515.212 295.548 511.362 295.548 507.347V475.027"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M542.235 431.844H328.276C319.642 431.844 312.643 438.842 312.643 447.474V490.929C312.643 499.562 319.642 506.56 328.276 506.56H542.235C550.869 506.56 557.868 499.562 557.868 490.929V447.474C557.868 438.842 550.869 431.844 542.235 431.844Z"
      fill="#C7C7FF"
    />
    <path
      d="M471.496 448.488H333.284C330.359 448.488 327.988 450.859 327.988 453.783V485.699C327.988 488.623 330.359 490.994 333.284 490.994H471.496C474.42 490.994 476.791 488.623 476.791 485.699V453.783C476.791 450.859 474.42 448.488 471.496 448.488Z"
      fill="#BBBBFF"
    />
    <path
      d="M446.699 466.392C449.129 466.392 451.099 464.422 451.099 461.992C451.099 459.562 449.129 457.593 446.699 457.593C444.269 457.593 442.299 459.562 442.299 461.992C442.299 464.422 444.269 466.392 446.699 466.392Z"
      fill="white"
    />
    <path
      d="M434.415 481.885C434.415 478.624 435.711 475.496 438.017 473.189C440.324 470.884 443.452 469.588 446.714 469.588C449.976 469.588 453.104 470.884 455.411 473.189C457.717 475.496 459.013 478.624 459.013 481.885H434.415Z"
      fill="white"
    />
    <path
      d="M514.33 448.493C510.046 448.489 505.855 449.756 502.291 452.133C498.726 454.51 495.946 457.891 494.305 461.849C492.662 465.806 492.231 470.161 493.064 474.363C493.899 478.566 495.961 482.427 498.99 485.457C502.019 488.487 505.878 490.551 510.081 491.387C514.283 492.224 518.64 491.795 522.599 490.156C526.558 488.517 529.941 485.74 532.321 482.177C534.701 478.615 535.971 474.426 535.97 470.142C535.97 467.3 535.411 464.485 534.324 461.859C533.238 459.233 531.644 456.847 529.634 454.836C527.625 452.826 525.24 451.231 522.614 450.143C519.988 449.055 517.173 448.494 514.33 448.493ZM514.33 486.565C513.902 486.585 513.474 486.517 513.073 486.368C512.672 486.217 512.305 485.987 511.994 485.691C511.684 485.396 511.438 485.04 511.269 484.645C511.1 484.251 511.013 483.827 511.013 483.399C511.013 482.97 511.1 482.545 511.269 482.151C511.438 481.757 511.684 481.402 511.994 481.105C512.305 480.81 512.672 480.579 513.073 480.43C513.474 480.279 513.902 480.212 514.33 480.232C515.145 480.27 515.914 480.62 516.477 481.21C517.04 481.799 517.354 482.583 517.354 483.399C517.354 484.214 517.04 484.997 516.477 485.587C515.914 486.177 515.145 486.527 514.33 486.565ZM518.314 462.211C517.631 466.911 517.077 471.843 515.677 476.393C515.362 477.493 513.292 477.493 512.943 476.393C511.951 472.964 511.192 469.472 510.675 465.941C510.238 463.379 508.741 459.212 510.176 456.725C511.44 454.491 515.007 454.225 517.057 455.359C519.612 456.807 518.587 459.874 518.314 462.211Z"
      fill="#F0F0FF"
    />
    <path
      d="M537.329 358.81C536.279 367.862 532.531 376.389 526.573 383.284C520.614 390.179 512.719 395.123 503.914 397.475C495.109 399.826 485.8 399.476 477.196 396.47C468.592 393.465 461.091 387.941 455.667 380.619C450.243 373.296 447.147 364.511 446.779 355.406C446.412 346.302 448.792 337.297 453.609 329.561C458.427 321.826 465.459 315.717 473.793 312.029C482.127 308.341 491.377 307.243 500.343 308.878C500.738 308.954 501.112 309.111 501.443 309.337C501.775 309.563 502.057 309.855 502.272 310.194C502.486 310.533 502.629 310.913 502.691 311.309C502.753 311.706 502.733 312.111 502.632 312.499L501.737 315.99L493.716 347.306C493.588 347.805 493.593 348.33 493.732 348.826C493.871 349.322 494.138 349.774 494.506 350.135C494.874 350.495 495.331 350.752 495.83 350.88C496.33 351.009 496.854 351.003 497.351 350.865L528.487 342.161L532.744 340.973C533.123 340.866 533.52 340.837 533.911 340.887C534.301 340.937 534.678 341.064 535.019 341.262C535.359 341.461 535.656 341.725 535.892 342.041C536.128 342.355 536.298 342.715 536.393 343.097C537.618 348.241 537.935 353.558 537.329 358.81Z"
      fill="#F0F0FF"
    />
    <path
      d="M538.401 326.537C538.549 326.919 538.614 327.327 538.593 327.736C538.573 328.145 538.467 328.544 538.282 328.91C538.097 329.275 537.838 329.597 537.521 329.855C537.204 330.114 536.835 330.303 536.44 330.411L533.509 331.231L508.153 338.315C507.656 338.453 507.132 338.458 506.633 338.33C506.133 338.201 505.677 337.945 505.308 337.584C504.94 337.223 504.673 336.772 504.534 336.276C504.396 335.779 504.39 335.255 504.518 334.756L511.07 309.261L511.699 306.815C511.798 306.426 511.976 306.061 512.222 305.744C512.467 305.427 512.776 305.165 513.128 304.972C513.48 304.78 513.868 304.662 514.268 304.627C514.667 304.591 515.07 304.638 515.45 304.765C520.609 306.545 525.326 309.409 529.285 313.165C533.244 316.921 536.352 321.48 538.401 326.537Z"
      fill="#FFF0F3"
    />
    <path
      d="M491.557 248.945C498.455 248.945 504.047 243.354 504.047 236.457C504.047 229.56 498.455 223.969 491.557 223.969C484.658 223.969 479.066 229.56 479.066 236.457C479.066 243.354 484.658 248.945 491.557 248.945Z"
      fill="#CEB7FF"
    />
    <path
      d="M537.629 264.056H446.706V266.556H537.629V264.056Z"
      fill="#CEB7FF"
    />
    <path
      d="M537.629 273.307H446.706V275.807H537.629V273.307Z"
      fill="#CEB7FF"
    />
    <path
      d="M537.629 281.738H446.706V284.239H537.629V281.738Z"
      fill="#CEB7FF"
    />
    <path
      d="M400.197 316.291H328.973V318.791H400.197V316.291Z"
      fill="#CEB7FF"
    />
    <path
      d="M400.197 326.381H328.973V328.881H400.197V326.381Z"
      fill="#CEB7FF"
    />
    <path
      d="M400.197 336.464H328.973V338.964H400.197V336.464Z"
      fill="#CEB7FF"
    />
    <path
      d="M400.197 346.555H328.973V349.055H400.197V346.555Z"
      fill="#CEB7FF"
    />
    <path
      d="M400.197 356.645H328.973V359.145H400.197V356.645Z"
      fill="#CEB7FF"
    />
    <path
      d="M319.202 245.244C319.202 245.244 340.848 279.326 365.514 290.038C390.18 300.75 397.887 305.484 407.323 301.651C416.759 297.819 434.873 292.449 447.636 254.514C460.4 216.579 466.481 196.61 466.932 187.422C467.383 178.234 442.006 185.783 442.006 185.783C442.006 185.783 428.341 226.054 398.4 244.028L355.757 198.995L319.202 245.244Z"
      fill="#F0F0FF"
    />
    <path
      d="M293.907 297.621L311.33 302.348C311.33 302.348 291.099 330.241 309.089 359.446C327.08 388.651 364.127 447.572 364.127 447.572L435.036 560.292L368.718 551.049L319.202 496.821L293.921 471.619L262.237 372.733L293.907 297.621Z"
      fill="#F0F0FF"
    />
    <path
      d="M9.42095 441.766C10.9875 442.613 12.8009 442.886 14.5477 442.537C16.2944 442.19 17.8647 441.242 18.9867 439.86L44.7733 408.25L65.5788 382.14L48.5449 372.576C46.7807 371.579 44.6926 371.325 42.7405 371.866C40.7878 372.408 39.1301 373.703 38.1319 375.466L6.38723 431.211C5.88154 432.1 5.5587 433.081 5.43769 434.097C5.31668 435.113 5.3999 436.143 5.6825 437.126C5.9651 438.109 6.44141 439.027 7.08341 439.823C7.72541 440.62 8.52012 441.28 9.42095 441.766Z"
      fill="#141414"
    />
    <path
      d="M401.931 590.863L423.392 611.973C425.245 613.799 427.74 614.824 430.341 614.828H484.012C485.677 614.835 487.307 614.357 488.705 613.453C490.103 612.549 491.207 611.257 491.883 609.735C492.559 608.215 492.778 606.529 492.512 604.886C492.246 603.243 491.508 601.712 490.387 600.482C488.976 598.904 487.026 597.91 484.921 597.695L455.151 594.641L401.931 590.863Z"
      fill="#141414"
    />
    <path
      d="M205.943 326.837C205.943 326.837 247.452 338.382 275.124 341.825C302.796 345.268 300.856 333.791 300.856 333.791L304.238 319.295C313.121 304.825 318.279 274.043 318.279 274.043L321.846 249.962L327.053 259.123L343.881 276.072L344.086 276.236C343.574 268.14 343.963 256.766 345.849 241.272C350.195 205.407 364.585 209.567 364.585 209.567L362.473 207.251C352.532 190.712 320.794 173.23 310.654 169.05C300.514 164.869 304.853 170.792 306.145 175.198C307.436 179.604 303.746 192.003 288.967 185.691C274.188 179.379 273.443 165.647 273.443 165.647H266.576C224.453 170.71 203.032 193.172 203.032 193.172C247.78 203.726 237.51 241.675 237.51 241.675L233.451 245.385C217.565 259.157 216.458 272.437 217.832 277.971C219.205 283.504 229.003 290.951 246.693 295.268C264.383 299.586 267.601 299.593 271.598 291.948C275.595 284.304 280.255 283.361 286.63 282.213C293.005 281.066 300.815 285.595 299.032 289.161C297.248 292.727 295.957 289.612 300.398 299.244C304.839 308.877 302.776 313.775 290.832 322.649C278.889 331.523 225.785 326.543 225.785 326.543C200.388 319.52 205.943 326.837 205.943 326.837Z"
      fill="#141414"
    />
    <path
      d="M303.028 165.876V167.331V167.7C314.901 170.666 326.082 175.914 335.948 183.152C337.547 184.321 339.105 185.509 340.642 186.759C340.683 181.738 340.253 176.697 338.634 171.942C337.322 167.861 334.85 164.252 331.521 161.551C328.589 159.215 325.125 157.719 321.641 156.366C318.156 155.013 314.733 153.825 311.535 152.001C308.84 150.527 306.486 148.502 304.627 146.057C303.975 145.173 303.569 144.133 303.449 143.041C303.329 141.95 303.5 140.846 303.944 139.841C303.63 139.819 303.32 139.757 303.021 139.656L303.028 165.876Z"
      fill="#6159FF"
    />
    <path
      d="M303.028 165.876V167.331V167.7C314.901 170.666 326.082 175.914 335.948 183.152C337.547 184.321 339.105 185.509 340.642 186.759C340.683 181.738 340.253 176.697 338.634 171.942C337.322 167.861 334.85 164.252 331.521 161.551C328.589 159.215 325.125 157.719 321.641 156.366C318.156 155.013 314.733 153.825 311.535 152.001C308.84 150.527 306.486 148.502 304.627 146.057C303.975 145.173 303.569 144.133 303.449 143.041C303.329 141.95 303.5 140.846 303.944 139.841C303.63 139.819 303.32 139.757 303.021 139.656L303.028 165.876Z"
      fill="#383494"
    />
    <path
      d="M138.996 279.293C143.457 282.484 148.416 284.915 153.672 286.486C155.933 287.183 158.231 287.757 160.553 288.208C151.377 266.586 158.244 238.14 187.201 207.808C192.158 202.606 197.437 197.718 203.005 193.175C212.003 185.858 219.998 180.407 229.427 176.021C233.648 174.073 237.983 172.383 242.409 170.959C250.256 168.347 258.356 166.567 266.576 165.651C268.441 165.425 270.286 165.261 272.152 165.159C271.696 161.955 271.787 158.699 272.418 155.526C274.14 146.816 277.139 141.809 282.592 134.943C286.008 130.619 291.345 120.29 284.232 116.723C279.743 114.49 279.333 109.892 281.444 107.159C283.227 104.782 286.835 103.696 291.283 106.476C294.358 108.444 298.006 108.567 301.532 107.603C304.271 106.008 306.644 103.858 308.501 101.291C313.667 93.8926 313.852 82.6821 305.768 77.2511C301.361 74.2794 295.745 73.5621 290.477 74.1359C288.118 74.3949 285.793 74.9031 283.542 75.6525C283.532 75.6478 283.521 75.6457 283.511 75.6457C283.5 75.6457 283.49 75.6478 283.48 75.6525C273.914 80.7967 267.116 90.3061 263.836 101.025C261.909 107.357 259.737 113.629 257.195 119.777C255.104 124.819 253.649 130.947 248.996 134.39C247.051 135.828 244.843 136.872 242.498 137.464C233.704 143.038 221.508 139.821 211.894 138.632C204.208 137.581 196.382 138.368 189.059 140.927C180.86 145.074 173.808 151.694 168.089 160.302C158.64 174.545 168.643 195.081 152.839 206.312C148.056 209.693 142.59 211.743 137.363 214.366C129.902 218.158 123.875 223.548 121.648 231.848C117.247 248.592 125.078 269.189 138.996 279.293Z"
      fill="#383494"
    />
    <path
      d="M525.419 614.826H151.506"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M316.742 94.5471C317.703 96.5378 318.524 98.592 319.202 100.695C320.186 104.111 319.12 106.229 320.616 109.488C321.129 110.615 321.867 112.521 322.666 114.447C322.892 113.996 323.097 113.627 323.22 113.341C329.082 100.286 321.375 93.9937 317.665 91.8418C317.428 92.7668 317.12 93.6713 316.742 94.5471Z"
      fill="#6159FF"
    />
    <path
      d="M316.742 94.5471C317.703 96.5378 318.524 98.592 319.202 100.695C320.186 104.111 319.12 106.229 320.616 109.488C321.129 110.615 321.867 112.521 322.666 114.447C322.892 113.996 323.097 113.627 323.22 113.341C329.082 100.286 321.375 93.9937 317.665 91.8418C317.428 92.7668 317.12 93.6713 316.742 94.5471Z"
      fill="#383494"
    />
    <path
      d="M186.838 314.788C183.258 343.016 186.155 391.984 243.973 429.448C254.379 436.191 263.747 445.714 272.923 453.946C297.951 476.559 321.76 500.484 344.25 525.622C358.018 540.883 372.072 556.924 386.032 572.377C393.188 580.274 400.421 588.098 407.732 595.85C413.116 601.527 421.657 614.821 430.314 614.821H483.514C488.297 614.821 492.642 610.906 492.574 606.104C492.535 604.028 491.749 602.037 490.36 600.495C488.949 598.917 487 597.923 484.894 597.708L455.124 594.654L311.289 365.949C303.868 354.145 297.289 334.716 303.028 321.989C324.893 273.581 323.014 226.662 323.014 226.662"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M261.308 444.162C255.036 466.194 244.91 488.567 236.547 502.654C233.516 507.796 229.521 512.306 224.781 515.934C207.644 529.057 183.422 527.657 166.99 513.673L85.2638 444.121L44.78 408.249L18.9934 439.859C17.7744 441.342 16.043 442.314 14.1418 442.583C12.2407 442.852 10.3077 442.399 8.7244 441.313C7.14113 440.227 6.02268 438.586 5.58997 436.716C5.15725 434.846 5.4417 432.881 6.38707 431.21L38.1386 375.479C39.1369 373.715 40.7945 372.421 42.7473 371.879C44.6994 371.337 46.7875 371.592 48.5517 372.589L65.5856 382.153L163.184 436.805C164.807 437.713 166.623 438.223 168.483 438.293C170.342 438.363 172.191 437.99 173.878 437.205C175.565 436.421 177.043 435.247 178.187 433.781C179.332 432.315 180.113 430.598 180.464 428.771L192.025 368.162"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M303.028 135.597V165.656V167.111V167.48C306.123 171.988 307.681 178.41 304.729 183.192C300.773 189.607 292.041 188.541 286.506 184.955C284.877 183.901 283.322 182.737 281.853 181.471C276.927 177.191 273.738 171.257 272.889 164.788C272.434 161.585 272.524 158.328 273.155 155.156C274.891 146.452 277.883 141.452 283.343 134.566C286.759 130.262 292.089 119.919 284.969 116.353C276.626 112.192 282.407 100.019 292.02 106.106C299.482 110.84 310.202 104.937 314.63 98.5909C319.795 91.1924 319.98 79.982 311.897 74.5509C307.49 71.5792 301.873 70.8619 296.605 71.4357C280.555 73.1983 269.131 85.7409 264.56 100.661C262.633 106.994 260.46 113.265 257.918 119.413C255.827 124.455 254.372 130.583 249.719 134.026C240.837 140.646 228.032 137.182 218.008 135.952C197.141 133.391 180.312 142.634 168.813 159.938C159.363 174.181 169.366 194.717 153.562 205.948C148.78 209.329 143.313 211.379 138.086 214.002C130.625 217.794 124.599 223.184 122.405 231.484C117.978 248.207 125.822 268.804 139.726 278.908C146.101 283.54 153.562 286.307 161.311 287.823"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M344.223 190.652C344.264 190.269 344.278 189.901 344.298 189.538C344.647 182.932 344.606 176.087 342.453 169.727C341.133 165.651 338.66 162.044 335.334 159.343C332.396 157.014 328.932 155.504 325.461 154.151C321.99 152.799 318.546 151.624 315.355 149.793C312.654 148.325 310.298 146.297 308.447 143.843C307.799 142.961 307.394 141.924 307.274 140.836C307.154 139.749 307.323 138.648 307.764 137.646"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M296.558 128.82C298.976 132.276 302.256 136.006 306.39 137.352C311.74 139.094 315.341 134.551 317.261 130.131C318.409 127.501 319.201 124.7 320.13 121.981C320.431 121.086 320.616 120.656 321.64 120.615C322.665 120.574 323.745 120.779 324.797 120.779C324.872 120.777 324.945 120.755 325.009 120.716C325.073 120.677 325.125 120.622 325.161 120.556C325.196 120.489 325.213 120.416 325.211 120.34C325.208 120.266 325.185 120.193 325.146 120.13C323.731 117.978 321.62 112.11 320.554 109.76C319.071 106.501 320.144 104.39 319.147 100.968C318.467 98.8663 317.651 96.8121 316.7 94.8193"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M318.279 88.501C318.279 88.501 332.867 94.096 325.331 110.908C324.866 111.926 323.725 114.051 323.185 115.007"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M475.63 40.5645C475.63 40.5645 475.712 40.6056 475.877 40.6674L475.63 40.5645Z"
      fill="#6159FF"
    />
    <path
      d="M317.473 129.647C316.305 129.808 315.119 129.536 314.137 128.885C313.154 128.234 312.444 127.247 312.137 126.108"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M319.939 107.131C316.919 104.672 313.605 107.814 311.583 110.096"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M272.91 164.794C262.782 165.435 252.778 167.38 243.147 170.58C227.432 175.731 216.609 182.378 203.77 192.796C198.204 197.349 192.925 202.243 187.966 207.45C158.982 237.782 152.129 266.242 161.319 287.856C168.787 305.468 186.005 316.392 203.647 321.959C215.188 325.594 227.213 327.158 239.28 327.506C253.663 327.916 268.168 327.37 282.326 324.637C288.291 323.49 295.164 322.547 298.724 317.006C302.284 311.466 302.585 303.992 299.94 298.056C297.46 292.529 290.764 286.586 284.71 290.924C284.039 291.408 283.487 292.039 283.095 292.768C282.704 293.497 282.482 294.305 282.449 295.132"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M261.972 201.343C262.723 223.996 243.524 236.627 243.524 236.627C237.155 241.785 230.965 247.326 225.759 253.672C222.431 257.723 219.343 262.259 217.812 267.335C216.668 270.797 216.687 274.536 217.867 277.985C219.171 281.175 221.327 283.944 224.098 285.992C229.277 290.043 235.577 292.14 241.822 293.957C248.703 295.926 255.712 297.419 262.799 298.425C266.898 299.013 268.627 297.742 270.499 294.196C272.194 290.986 273.581 287.269 276.717 285.172C281.062 282.255 286.898 281.579 291.926 282.781C299.682 284.625 301.663 290.774 295.343 292.017"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M257.236 355.031L139.406 326.564L111.364 442.591L225.928 471.064L229.604 469.377L257.236 355.031Z"
      fill="white"
    />
    <path
      d="M202.868 338.718C208.457 299.601 220.572 289.928 228.457 287.995L227.937 287.708C204.132 278.444 187.624 324.277 184.119 334.995L202.868 338.718Z"
      fill="#CEB7FF"
    />
    <path
      d="M202.539 341.819C208.128 302.701 220.57 289.899 228.455 287.993L227.936 287.706C204.131 278.443 186.94 326.482 183.421 337.194L202.539 341.819Z"
      fill="#CEB7FF"
    />
    <path
      d="M227.936 287.706C201.015 277.24 183.421 337.193 183.421 337.193L202.552 341.818C213.669 281.701 237.522 292.632 237.522 292.632C236.682 292.379 227.936 287.706 227.936 287.706Z"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M138.093 332.094L111.368 442.627L204.328 465.095L231.052 354.562L138.093 332.094Z"
      fill="#6159FF"
    />
    <path
      d="M139.512 326.243L111.371 442.633L209.252 466.291L237.393 349.901L139.512 326.243Z"
      fill="white"
    />
    <path
      d="M139.973 335.095L115.736 435.335C115.05 438.177 116.797 441.038 119.639 441.725L202.305 461.705C205.148 462.393 208.009 460.645 208.696 457.803L232.933 357.563C233.62 354.72 231.872 351.859 229.03 351.173L146.364 331.193C143.522 330.505 140.661 332.252 139.973 335.095Z"
      fill="#9984FF"
    />
    <path
      d="M139.424 326.606L111.371 442.631L209.252 466.289L237.305 350.264L139.424 326.606Z"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M237.717 350.355L209.664 466.38L229.204 471.103L257.256 355.078L237.717 350.355Z"
      fill="white"
    />
    <path
      d="M237.717 350.355L209.664 466.38L229.204 471.103L257.256 355.078L237.717 350.355Z"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M229.195 471.066L222.902 454.882L209.653 466.345"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M247.472 352.676L222.901 454.882"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M463.016 164.461C462.647 164.522 462.333 164.584 461.93 164.666C458.233 165.311 454.729 166.782 451.681 168.97C448.339 171.552 445.306 176.04 443.83 179.9C441.598 185.762 439.145 191.527 436.471 197.197C432.434 205.938 427.632 214.305 422.122 222.201C416.403 230.235 409.536 238.378 400.886 243.378C399.801 244.034 398.657 244.59 397.47 245.038C396.015 245.653 375.537 222.098 373.282 219.475C364.242 208.961 354.895 198.55 344.298 189.553C341.613 187.258 338.832 185.044 335.976 182.974C326.102 175.73 314.914 170.475 303.035 167.501"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M499.858 163.598C499.898 163.608 499.941 163.608 499.981 163.598C509.451 164.704 505.741 174.911 499.079 174.583C491.72 174.214 484.382 174.583 477.044 174.706C475.021 174.639 473.007 175.01 471.14 175.792C467.451 177.554 467.102 181.489 466.549 185.055C465.811 189.728 464.97 194.401 463.98 199.033C462.053 208.214 459.675 217.273 456.949 226.249C455.186 232.07 453.239 237.863 451.189 243.594C445.203 260.482 437.66 278.394 424.972 291.415C417.961 298.629 408.778 303.958 398.427 303.179C383.852 302.093 369.832 294.776 357.471 286.455C357.419 286.42 357.371 286.379 357.328 286.332C352.714 283.227 348.292 279.846 344.086 276.208C344.086 276.208 327.052 261.042 321.846 249.934"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      d="M526.785 154.419V155.376C526.785 161.975 521.469 163.109 516.877 163.218L428.004 165.268C424.11 165.37 420.837 161.961 420.755 157.705V157.528C420.755 153.353 423.816 149.883 427.663 149.787C427.663 149.787 477.377 148.387 515.88 147.328C516.611 149.658 518.401 151.461 520.622 152.868C522.477 153.984 524.622 154.524 526.785 154.419Z"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
    <path
      opacity="0.2"
      d="M465.92 43.6398C477.972 43.6398 487.743 33.8705 487.743 21.8198C487.743 9.76903 477.972 0 465.92 0C453.867 0 444.096 9.76903 444.096 21.8198C444.096 33.8705 453.867 43.6398 465.92 43.6398Z"
      fill="#CEB7FF"
    />
    <g opacity="0.2">
      <path
        opacity="0.72"
        d="M447.506 89.1562C454.14 89.1562 459.518 83.7799 459.518 77.1465C459.518 70.5138 454.14 65.1367 447.506 65.1367C440.872 65.1367 435.494 70.5138 435.494 77.1465C435.494 83.7799 440.872 89.1562 447.506 89.1562Z"
        fill="#6159FF"
      />
    </g>
    <path
      opacity="0.5"
      d="M515.127 50.9358L503.339 57.2187C499.063 59.497 497.445 64.8105 499.724 69.085L506.008 80.8707C508.287 85.1458 513.601 86.7642 517.877 84.4852L529.664 78.203C533.94 75.924 535.559 70.6111 533.28 66.336L526.996 54.5503C524.717 50.2758 519.403 48.6575 515.127 50.9358Z"
      fill="#CEB7FF"
    />
    <path
      d="M519.851 81.1359L528.385 76.5862C530.165 75.6311 531.494 74.0114 532.083 72.0801C532.672 70.1482 532.473 68.0625 531.528 66.2774L526.062 56.0302C525.108 54.2499 523.488 52.9198 521.556 52.3309C519.624 51.7421 517.536 51.9422 515.752 52.8877L515.178 53.1951C513.046 56.1532 510.21 61.2153 514.495 62.807C520.644 65.1093 518.847 68.5592 516.544 72.9791C514.59 76.6886 514.091 81.0266 519.851 81.1359Z"
      fill="white"
    />
    <path
      d="M506.56 68.7312C508.341 68.7312 509.785 67.287 509.785 65.5067C509.785 63.7257 508.341 62.2822 506.56 62.2822C504.779 62.2822 503.335 63.7257 503.335 65.5067C503.335 67.287 504.779 68.7312 506.56 68.7312Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M466.837 122.549C468.032 123.13 469.304 123.535 470.615 123.752C473.022 124.12 475.483 123.859 477.759 122.992C480.035 122.125 482.046 120.683 483.597 118.806C484.793 117.316 485.688 115.451 486.945 113.948C487.805 112.852 488.955 112.019 490.266 111.544C490.715 111.448 491.135 111.244 491.489 110.949C492.35 110.061 490.888 109.31 490.594 108.592C490.153 107.435 490.153 106.156 490.594 104.999C491.716 101.957 491.747 98.6198 490.683 95.5566C489.619 92.4933 487.525 89.894 484.759 88.2018C481.993 86.509 478.725 85.8285 475.513 86.2753C472.301 86.7228 469.343 88.2701 467.144 90.6529C464.971 92.9962 463.666 95.9883 462.313 98.8507C461.111 101.399 459.833 103.933 459.17 106.673C457.633 113.163 461.104 119.755 466.837 122.549Z"
      fill="#CEB7FF"
    />
    <path
      d="M479.04 99.4931C480.719 99.4931 482.08 98.1323 482.08 96.4531C482.08 94.7739 480.719 93.4131 479.04 93.4131C477.36 93.4131 475.999 94.7739 475.999 96.4531C475.999 98.1323 477.36 99.4931 479.04 99.4931Z"
      fill="white"
    />
    <path
      d="M475.055 107.884C476.735 107.884 478.096 106.523 478.096 104.844C478.096 103.165 476.735 101.804 475.055 101.804C473.376 101.804 472.015 103.165 472.015 104.844C472.015 106.523 473.376 107.884 475.055 107.884Z"
      fill="white"
    />
    <path
      d="M471.318 115.752C472.997 115.752 474.358 114.39 474.358 112.712C474.358 111.033 472.997 109.672 471.318 109.672C469.638 109.672 468.277 111.033 468.277 112.712C468.277 114.39 469.638 115.752 471.318 115.752Z"
      fill="white"
    />
    <g opacity="0.54">
      <path
        opacity="0.54"
        d="M398.332 134.934C399.256 134.812 400.169 134.622 401.065 134.367L411.355 131.47C414.505 130.582 417.819 129.598 420.053 127.201C424.194 122.753 422.854 115.69 421.249 109.828C419.942 105.032 418.632 100.237 417.32 95.441C416.418 92.1346 415.489 88.7735 413.692 85.8769C411.895 82.9804 409.052 80.4937 405.663 79.9609C403.169 79.5715 400.641 80.2409 398.202 80.9036L388.329 83.6362C385.09 84.5175 381.674 85.508 379.405 87.9879C375.374 92.3942 376.672 99.3077 378.216 105.067L382.159 119.788C384.578 128.635 389.108 136.095 398.332 134.934Z"
        fill="#CEB7FF"
      />
    </g>
    <path
      d="M574 60.3967C573.999 61.4843 573.741 62.5561 573.248 63.5255L533.53 144.991C533.063 145.932 532.398 146.762 531.581 147.422C530.764 148.082 529.813 148.558 528.795 148.817C527.153 149.232 525.418 149.052 523.896 148.311C522.679 147.713 521.654 146.786 520.938 145.635C520.222 144.484 519.843 143.156 519.844 141.801C519.85 140.717 520.097 139.648 520.568 138.672L560.307 57.1996C561.029 55.7444 562.222 54.5763 563.691 53.8835C565.161 53.1915 566.821 53.016 568.403 53.3849C569.985 53.7544 571.396 54.6473 572.406 55.9187C573.418 57.19 573.969 58.7653 573.972 60.3899L574 60.3967Z"
      stroke="#141414"
      stroke-width="1.98"
      stroke-miterlimit="10"
    />
  </svg>
)
export default RegisterWelcomeImage
