import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const CollapseFilterIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9375 7C10.9375 7.11603 10.8914 7.22731 10.8094 7.30936C10.7273 7.39141 10.616 7.4375 10.5 7.4375H3.5C3.38397 7.4375 3.27269 7.39141 3.19064 7.30936C3.10859 7.22731 3.0625 7.11603 3.0625 7C3.0625 6.88397 3.10859 6.77269 3.19064 6.69064C3.27269 6.60859 3.38397 6.5625 3.5 6.5625H10.5C10.616 6.5625 10.7273 6.60859 10.8094 6.69064C10.8914 6.77269 10.9375 6.88397 10.9375 7ZM12.6875 3.9375H1.3125C1.19647 3.9375 1.08519 3.98359 1.00314 4.06564C0.921094 4.14769 0.875 4.25897 0.875 4.375C0.875 4.49103 0.921094 4.60231 1.00314 4.68436C1.08519 4.76641 1.19647 4.8125 1.3125 4.8125H12.6875C12.8035 4.8125 12.9148 4.76641 12.9969 4.68436C13.0789 4.60231 13.125 4.49103 13.125 4.375C13.125 4.25897 13.0789 4.14769 12.9969 4.06564C12.9148 3.98359 12.8035 3.9375 12.6875 3.9375ZM8.3125 9.1875H5.6875C5.57147 9.1875 5.46019 9.23359 5.37814 9.31564C5.29609 9.39769 5.25 9.50897 5.25 9.625C5.25 9.74103 5.29609 9.85231 5.37814 9.93436C5.46019 10.0164 5.57147 10.0625 5.6875 10.0625H8.3125C8.42853 10.0625 8.53981 10.0164 8.62186 9.93436C8.70391 9.85231 8.75 9.74103 8.75 9.625C8.75 9.50897 8.70391 9.39769 8.62186 9.31564C8.53981 9.23359 8.42853 9.1875 8.3125 9.1875Z"
      fill="#6159FF"
    />
  </svg>
)
