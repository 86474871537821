import "./styles.scss"

export const FileErrorIcon = ({ ...props }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.7076 24.2921C25.8005 24.385 25.8742 24.4953 25.9245 24.6167C25.9747 24.7381 26.0006 24.8682 26.0006 24.9996C26.0006 25.131 25.9747 25.2611 25.9245 25.3825C25.8742 25.5039 25.8005 25.6142 25.7076 25.7071C25.6147 25.8 25.5044 25.8737 25.383 25.924C25.2616 25.9743 25.1315 26.0001 25.0001 26.0001C24.8687 26.0001 24.7386 25.9743 24.6172 25.924C24.4958 25.8737 24.3855 25.8 24.2926 25.7071L16.0001 17.4133L7.70757 25.7071C7.51993 25.8947 7.26543 26.0001 7.00007 26.0001C6.7347 26.0001 6.48021 25.8947 6.29257 25.7071C6.10493 25.5194 5.99951 25.2649 5.99951 24.9996C5.99951 24.7342 6.10493 24.4797 6.29257 24.2921L14.5863 15.9996L6.29257 7.70708C6.10493 7.51944 5.99951 7.26494 5.99951 6.99958C5.99951 6.73422 6.10493 6.47972 6.29257 6.29208C6.48021 6.10444 6.7347 5.99902 7.00007 5.99902C7.26543 5.99902 7.51993 6.10444 7.70757 6.29208L16.0001 14.5858L24.2926 6.29208C24.4802 6.10444 24.7347 5.99902 25.0001 5.99902C25.2654 5.99902 25.5199 6.10444 25.7076 6.29208C25.8952 6.47972 26.0006 6.73422 26.0006 6.99958C26.0006 7.26494 25.8952 7.51944 25.7076 7.70708L17.4138 15.9996L25.7076 24.2921Z"
      fill="#CF133C"
    />
  </svg>
)
