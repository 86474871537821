import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

interface ArrowCounterIconProps
  extends JSX.IntrinsicAttributes,
    SVGProps<SVGSVGElement> {
  className?: string
}

export const IncomeIcon = ({
  className = "",
  ...props
}: ArrowCounterIconProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} arrow-color-icon`}
    {...props}
  >
    <path d="M15.023 5.78953L11.0855 1.85203C11.0332 1.79981 10.9711 1.7584 10.9029 1.73017C10.8346 1.70194 10.7614 1.68744 10.6875 1.6875H3.9375C3.63913 1.6875 3.35298 1.80603 3.142 2.017C2.93103 2.22798 2.8125 2.51413 2.8125 2.8125V15.1875C2.8125 15.4859 2.93103 15.772 3.142 15.983C3.35298 16.194 3.63913 16.3125 3.9375 16.3125H14.0625C14.3609 16.3125 14.647 16.194 14.858 15.983C15.069 15.772 15.1875 15.4859 15.1875 15.1875V6.1875C15.1876 6.11361 15.1731 6.04043 15.1448 5.97215C15.1166 5.90386 15.0752 5.84181 15.023 5.78953ZM11.25 3.60773L13.2673 5.625H11.25V3.60773ZM14.0625 15.1875H3.9375V2.8125H10.125V6.1875C10.125 6.33668 10.1843 6.47976 10.2898 6.58525C10.3952 6.69074 10.5383 6.75 10.6875 6.75H14.0625V15.1875Z" />
    <rect x="6" y="6" width="12" height="12" rx="6" fill="#f0f0ff" />
    <path d="M16.6875 12C16.6875 12.1492 16.6282 12.2923 16.5227 12.3977C16.4173 12.5032 16.2742 12.5625 16.125 12.5625H12.5625V16.125C12.5625 16.2742 12.5032 16.4173 12.3977 16.5227C12.2923 16.6282 12.1492 16.6875 12 16.6875C11.8508 16.6875 11.7077 16.6282 11.6023 16.5227C11.4968 16.4173 11.4375 16.2742 11.4375 16.125V12.5625H7.875C7.72582 12.5625 7.58274 12.5032 7.47725 12.3977C7.37176 12.2923 7.3125 12.1492 7.3125 12C7.3125 11.8508 7.37176 11.7077 7.47725 11.6023C7.58274 11.4968 7.72582 11.4375 7.875 11.4375H11.4375V7.875C11.4375 7.72582 11.4968 7.58274 11.6023 7.47725C11.7077 7.37176 11.8508 7.3125 12 7.3125C12.1492 7.3125 12.2923 7.37176 12.3977 7.47725C12.5032 7.58274 12.5625 7.72582 12.5625 7.875V11.4375H16.125C16.2742 11.4375 16.4173 11.4968 16.5227 11.6023C16.6282 11.7077 16.6875 11.8508 16.6875 12Z" />
  </svg>
)
