import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

interface ArrowCounterIconProps
  extends JSX.IntrinsicAttributes,
    SVGProps<SVGSVGElement> {
  className?: string
}

export const ArrowCounterIcon = ({
  className = "",
  ...props
}: ArrowCounterIconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`${className} arrow-color-icon`}
  >
    <path
      d="M4.8125 5.6875H2.1875C2.07147 5.6875 1.96019 5.64141 1.87814 5.55936C1.79609 5.47731 1.75 5.36603 1.75 5.25V2.625C1.75 2.50897 1.79609 2.39769 1.87814 2.31564C1.96019 2.23359 2.07147 2.1875 2.1875 2.1875C2.30353 2.1875 2.41481 2.23359 2.49686 2.31564C2.57891 2.39769 2.625 2.50897 2.625 2.625V3.9293C3.47102 2.975 4.95523 1.75 7 1.75C9.34172 1.75 10.7505 3.13195 10.8095 3.19047C10.8918 3.27242 10.9381 3.38368 10.9383 3.49977C10.9385 3.61587 10.8926 3.72729 10.8106 3.80953C10.7287 3.89177 10.6174 3.93809 10.5013 3.93829C10.3852 3.9385 10.2738 3.89257 10.1916 3.81063C10.1768 3.79586 8.96438 2.625 7 2.625C5.08594 2.625 3.70617 3.97305 3.03187 4.8125H4.8125C4.92853 4.8125 5.03981 4.85859 5.12186 4.94064C5.20391 5.02269 5.25 5.13397 5.25 5.25C5.25 5.36603 5.20391 5.47731 5.12186 5.55936C5.03981 5.64141 4.92853 5.6875 4.8125 5.6875ZM11.8125 8.3125H9.1875C9.07147 8.3125 8.96019 8.35859 8.87814 8.44064C8.79609 8.52269 8.75 8.63397 8.75 8.75C8.75 8.86603 8.79609 8.97731 8.87814 9.05936C8.96019 9.14141 9.07147 9.1875 9.1875 9.1875H10.9681C10.2938 10.027 8.91406 11.375 7 11.375C5.03562 11.375 3.8232 10.2041 3.80844 10.1894C3.7262 10.1074 3.61478 10.0615 3.49868 10.0617C3.38258 10.0619 3.27132 10.1082 3.18937 10.1905C3.10743 10.2727 3.0615 10.3841 3.06171 10.5002C3.06191 10.6163 3.10823 10.7276 3.19047 10.8095C3.24953 10.868 4.65828 12.25 7 12.25C9.04477 12.25 10.529 11.025 11.375 10.0707V11.375C11.375 11.491 11.4211 11.6023 11.5031 11.6844C11.5852 11.7664 11.6965 11.8125 11.8125 11.8125C11.9285 11.8125 12.0398 11.7664 12.1219 11.6844C12.2039 11.6023 12.25 11.491 12.25 11.375V8.75C12.25 8.63397 12.2039 8.52269 12.1219 8.44064C12.0398 8.35859 11.9285 8.3125 11.8125 8.3125Z"
      fill="#6159FF"
    />
  </svg>
)
