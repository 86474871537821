import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

interface ArrowCounterIconProps
  extends JSX.IntrinsicAttributes,
    SVGProps<SVGSVGElement> {
  className?: string
}

export const MenuTaxesIcon = ({
  className = "",
  ...props
}: ArrowCounterIconProps) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} arrow-color-icon`}
    {...props}
  >
    <path d="M8.42773 8.3125C9.29798 8.3125 10.1326 7.9668 10.7479 7.35144C11.3633 6.73609 11.709 5.90149 11.709 5.03125C11.709 4.16101 11.3633 3.32641 10.7479 2.71106C10.1326 2.0957 9.29798 1.75 8.42773 1.75H5.14648C5.03045 1.75 4.91917 1.79609 4.83713 1.87814C4.75508 1.96019 4.70898 2.07147 4.70898 2.1875V7.4375H3.39648C3.28045 7.4375 3.16917 7.48359 3.08713 7.56564C3.00508 7.64769 2.95898 7.75897 2.95898 7.875C2.95898 7.99103 3.00508 8.10231 3.08713 8.18436C3.16917 8.26641 3.28045 8.3125 3.39648 8.3125H4.70898V9.1875H3.39648C3.28045 9.1875 3.16917 9.23359 3.08713 9.31564C3.00508 9.39769 2.95898 9.50897 2.95898 9.625C2.95898 9.74103 3.00508 9.85231 3.08713 9.93436C3.16917 10.0164 3.28045 10.0625 3.39648 10.0625H4.70898V11.8125C4.70898 11.9285 4.75508 12.0398 4.83713 12.1219C4.91917 12.2039 5.03045 12.25 5.14648 12.25C5.26252 12.25 5.3738 12.2039 5.45584 12.1219C5.53789 12.0398 5.58398 11.9285 5.58398 11.8125V10.0625H8.20898C8.32502 10.0625 8.4363 10.0164 8.51834 9.93436C8.60039 9.85231 8.64648 9.74103 8.64648 9.625C8.64648 9.50897 8.60039 9.39769 8.51834 9.31564C8.4363 9.23359 8.32502 9.1875 8.20898 9.1875H5.58398V8.3125H8.42773ZM5.58398 2.625H8.42773C9.06591 2.625 9.67795 2.87852 10.1292 3.32977C10.5805 3.78103 10.834 4.39307 10.834 5.03125C10.834 5.66943 10.5805 6.28147 10.1292 6.73273C9.67795 7.18399 9.06591 7.4375 8.42773 7.4375H5.58398V2.625Z" />
  </svg>
)
