import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const LoadApiIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5 19.5H7.5C7.10218 19.5 6.72064 19.342 6.43934 19.0607C6.15804 18.7794 6 18.3978 6 18V9C6 8.60218 6.15804 8.22064 6.43934 7.93934C6.72064 7.65804 7.10218 7.5 7.5 7.5C7.89782 7.5 8.27936 7.65804 8.56066 7.93934C8.84196 8.22064 9 8.60218 9 9V13.4719C11.9006 10.2 16.9894 6 24 6C32.0287 6 36.8588 10.7381 37.0613 10.9388C37.3432 11.2197 37.502 11.6012 37.5027 11.9992C37.5034 12.3973 37.346 12.7793 37.065 13.0612C36.784 13.3432 36.4026 13.502 36.0045 13.5027C35.6065 13.5034 35.2245 13.346 34.9425 13.065C34.8919 13.0144 30.735 9 24 9C17.4375 9 12.7069 13.6219 10.395 16.5H16.5C16.8978 16.5 17.2794 16.658 17.5607 16.9393C17.842 17.2206 18 17.6022 18 18C18 18.3978 17.842 18.7794 17.5607 19.0607C17.2794 19.342 16.8978 19.5 16.5 19.5ZM40.5 28.5H31.5C31.1022 28.5 30.7206 28.658 30.4393 28.9393C30.158 29.2206 30 29.6022 30 30C30 30.3978 30.158 30.7794 30.4393 31.0607C30.7206 31.342 31.1022 31.5 31.5 31.5H37.605C35.2931 34.3781 30.5625 39 24 39C17.265 39 13.1081 34.9856 13.0575 34.935C12.7755 34.654 12.3935 34.4966 11.9955 34.4973C11.5974 34.498 11.216 34.6568 10.935 34.9387C10.654 35.2207 10.4966 35.6027 10.4973 36.0008C10.498 36.3988 10.6568 36.7803 10.9388 37.0613C11.1413 37.2619 15.9713 42 24 42C31.0106 42 36.0994 37.8 39 34.5281V39C39 39.3978 39.158 39.7794 39.4393 40.0607C39.7206 40.342 40.1022 40.5 40.5 40.5C40.8978 40.5 41.2794 40.342 41.5607 40.0607C41.842 39.7794 42 39.3978 42 39V30C42 29.6022 41.842 29.2206 41.5607 28.9393C41.2794 28.658 40.8978 28.5 40.5 28.5Z"
      fill="#D1D1D1"
    />
  </svg>
)
