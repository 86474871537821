import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const EditIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.431 4.01284L9.98758 1.56885C9.90633 1.48758 9.80986 1.42311 9.70369 1.37912C9.59751 1.33514 9.48372 1.3125 9.36879 1.3125C9.25387 1.3125 9.14007 1.33514 9.0339 1.37912C8.92773 1.42311 8.83126 1.48758 8.75 1.56885L2.00649 8.31291C1.92488 8.39387 1.86019 8.49024 1.81615 8.59642C1.77212 8.70261 1.74964 8.81648 1.75 8.93143V11.3754C1.75 11.6075 1.84219 11.83 2.00629 11.9941C2.17038 12.1582 2.39294 12.2504 2.625 12.2504H5.06899C5.18394 12.2508 5.29781 12.2283 5.404 12.1843C5.51018 12.1402 5.60655 12.0755 5.6875 11.9939L12.431 5.25041C12.5123 5.16916 12.5768 5.07269 12.6207 4.96652C12.6647 4.86035 12.6874 4.74655 12.6874 4.63163C12.6874 4.5167 12.6647 4.4029 12.6207 4.29673C12.5768 4.19056 12.5123 4.09409 12.431 4.01284ZM5.06899 11.3754H2.625V8.93143L7.4375 4.11893L9.88149 6.56291L5.06899 11.3754ZM10.5 5.94385L8.05602 3.50041L9.36852 2.18791L11.8125 4.63135L10.5 5.94385Z"
      fill="#6159FF"
    />
  </svg>
)
